import ApiService from "./api.service";
const ManualService = {
  GetOrganizationAsSelectList(parentId, authorizedOnly, inspectionOnly) {
    return ApiService.get(
      `/Manual/GetOrganizationAsSelectList?parentId=${parentId}&authorizedOnly=${authorizedOnly}&inspectionOnly=${inspectionOnly}`
    );
  },
  GetOkedAsSelectList(level) {
    return ApiService.get(`/Manual/GetOkedAsSelectList?level=${level}`);
  },
  GetOrgAsSelectList() {
    return ApiService.get(`/Manual/GetOrgAsSelectList`);
  },
  OrgActivityTypeAsSelectList(organizationId) {
    return ApiService.get(
      `/Manual/OrgActivityTypeAsSelectList?organizationId=${organizationId}`
    );
  },
  AsOblOfEntTableSelectList(organizationId, organizationActivityTypeId) {
    return ApiService.get(
      `/Manual/AsOblOfEntTableSelectList?organizationId=${organizationId}&organizationActivityTypeId=${organizationActivityTypeId}`
    );
  },
};
export default ManualService;
